@import url('https://fonts.googleapis.com/css2?family=Ysabeau:wght@300&display=swap');

body {
  background-color: #252525;
  color: white;
}

h1 {
  font-family: 'Ysabeau', sans-serif;
  font-size: 48px;
  text-align: center;
}

p{
  font-family: 'Ysabeau', sans-serif;
  font-size: 30px;
  text-align: center;
}